/* You can add global styles to this file, and also import other style files */

body,
html {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-color: #004479;
    display: flex;
    flex: 1 0 auto;
    height: 100%;
    width: 100%;
    max-height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-size: 14px;
}

.app-container {
    position: relative;
    display: flex;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    min-width: 0;
}

.app-root {
    display: flex;
    height: 100%;
    width: 100%;
}

.main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.app-height {
    display: inherit;
}

.header {
    width: 100%;
}

.standardBackgroundColor {
    background-color: #ecf0f9;
}

.loading-shade {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-shade-add {
    position: absolute;
    top: 0;
    left: 0;
    bottom: -20px;
    right: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.margin-5 {
    margin: 5px;
}

.fixed-header-table .mat-row.lightgrey-background {
    background: lightgrey !important;
}

.mat-toolbar.mat-primary {
    height: 40px;
    min-height: 40px;
    background: #0e5b9a;
    color: #fff;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-size: 15px;
    font-family: inherit;
}

.mat-toolbar-row {
    justify-content: space-between;
}

.mat-button-toggle-custom .mat-button-toggle-button .mat-button-toggle-label-content {
    line-height: 30px !important;
    padding: 0 !important;
}

.mat-row.hovered {
    background: #eee;
}

.mat-row.highlighted {
    background: #999;
}

.mat-button-toggle-appearance-standard {
    background-color: #e0e0e0;
}

.mat-button-toggle-checked {
    background-color: #2196f3;
}

.wrapper {
    background-image: linear-gradient(to bottom, #fff, #ddd);
    border-spacing: 5px;
}

.left-area {
    max-width: 99%;
    min-width: 1%;
}

.right-area {
    max-width: 99%;
    min-width: 1%;
}

.fitting-manager {
    margin-right: 25px;
}

.expand-newfittings-table-content {
    height: calc(130vh - 450px);
}

.fixed-header-table {
    table-layout: fixed;
    min-width: min-content;
}

.max-height {
    height: calc(125vh - 450px);
    overflow: auto;
}

.add-fitting-size-button-group {
    position: absolute;
    bottom: 20px;
    right: 15px;
}

.add-fitting-size-button {
    margin-right: 23px;
}

.mat-autocomplete-panel mat-option.mat-option {
    height: unset;
    line-height: 36px;
}

.mat-option-text.mat-option-text:hover {
    overflow: visible;
}

.hiddenOverflow {
    overflow: hidden;
}

.mat-form-field-infix {
    width: 250px !important;
}

.left-panel {
    padding: 25px;
}

.nav-bar {
    margin-top: 3px;
    margin-bottom: 5px;
    border-radius: 4px;
}

.main-grid {
    display: flex !important;
}

.main-height {
    height: 91.8vh;
}

.max-height {
    overflow: auto;
    max-width: 161vh;
}

.as-split-gutter {
    background-color: unset !important;
    flex-basis: 5px !important;
}

.flex-left {
    display: flex;
    flex-direction: column;
    align-content: space-between;
}

.mat-card-grow {
    flex-grow: 1;
}

:root .mat-card-subtitle {
    margin-bottom: 0;
    font-size: 16px;
    color: rgb(10, 10, 10);
}

.mat-cell {
    font-size: 12px;
    padding: 0.5em;
    }

.mat-tooltip {
    font-size: 12px!important;
}

:root .mat-card{
  padding: 0.75rem;
}

@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";